import React from "react"
import SEO from "../components/seo"

import { Header } from "../components/Header"
import { Hero } from "../components/Hero"
import { Features } from "../components/Features"
import { Testimonials } from "../components/Testimonials"
import { Services } from "../components/Services"
import { Footer } from "../components/Footer"

const heroData = {
  title: () => <>Cotiza Fletes Atizapan</>,
  copy: () => (
    <>
      Cotiza Fletes Atizapan
      <br /> de forma sencilla y segura
      <br />
      Obtendrás un servicio profesional,
      <br />
      rápido y seguro.
    </>
  ),
}

const featuresData = {
  copies: [
    {
      title: () => (
        <>
          Cotiza tu Fletes
          <br />
          Atizapan
        </>
      ),
      text: "Solo indícanos algunos datos y cotizaremos tu flete",
    },
    {
      title: () => (
        <>
          Reserva tu Fletes
          <br />
          Atizapan
        </>
      ),
      text:
        "Reserva el día y la hora de tu flete con una parte o bien paga por adelantado",
    },
    {
      title: () => (
        <>
          Realiza tu Fletes
          <br />
          Atizapan
        </>
      ),
      text:
        "Nos comprometemos a llegar en tiempo y forma a tu servicio y realizarlo de forma profesional",
    },
    {
      title: () => <>Disfruta</>,
      text:
        "¡No te preocupes! Nosotros nos encargamos de todo lo necesario para que estés tranquilo",
    },
  ],
}

const IndexPage = () => (
  <>
    <SEO title="Tu Flete-Fletes Atizapan" />
    <Header />
    <Hero {...heroData} />
    <Features {...featuresData} />
    <Testimonials />
    <Services />
    <Footer />
  </>
)

export default IndexPage
